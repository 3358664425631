import { FC, useState, useEffect } from "react";
import { axiosPost } from "@root/utils/axiosForRails";
import { AxiosError } from "axios";
import { Presentation } from "./Presentation";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

type RestoreData = {
  siteId: 1 | 2;
  jobEntryId: string;
  email: string;
  businessTypeId: number;
  employmentStatus: number;
  employmentId: number;
  atmosphere: number;
  salaryStandard: number;
  debut: number;
  education: number;
  workTime: number;
  salaryChange: number;
  workplaceMatching: string;
  interestReward: string;
  challenge: string;
  expectation: string;
  treatments: number[];
  cultures: number[];
};

type Props = {
  initialIsShow: boolean;
  restoreBackupUrl: string;
  deleteBackupUrl: string;
  onRestore: (data: RestoreData) => void;
};

type Response = {
  status?: number;
  data: RestoreData;
};

export const RestoreUserRewardContinuedInputsModal: FC<Props> = ({
  initialIsShow,
  restoreBackupUrl,
  deleteBackupUrl,
  onRestore,
}) => {
  const [isShow, setIsShow] = useState(initialIsShow);

  const restoreBackup = async () => {
    await axiosPost
      .post(restoreBackupUrl)
      .then((response: Response) => {
        if (response.status === 200) {
          // レスポンスデータを親コンポーネントに渡す
          onRestore(response.data);
        }
      })
      .catch((error: AxiosError) => {
        if (typeof newrelic !== "undefined") {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          newrelic.noticeError(error.message);
        }
      });
    setIsShow(false);
  };

  const deleteBackup = async () => {
    await axiosPost.post(deleteBackupUrl).catch((error: AxiosError) => {
      if (typeof newrelic !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        newrelic.noticeError(error.message);
      }
    });
    setIsShow(false);
  };

  useEffect(() => {
    setIsShow(initialIsShow);
  }, [initialIsShow]);

  return (
    <Presentation
      isShow={isShow}
      closeModalHandler={() => setIsShow(false)}
      restoreBackupHandler={restoreBackup}
      deleteBackupHandler={deleteBackup}
    />
  );
};
