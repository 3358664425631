import { FC, ReactNode } from "react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import * as styles from "./styles.css";

type Props = JSX.IntrinsicElements["a"] & {
  icon?: ReactNode;
  isBold?: boolean;
};

export const TextLink: FC<Props> = ({ children, icon, isBold, className, ...rest }) => (
  <a
    className={`${styles.linkOuter} ${className || ""}`}
    style={assignInlineVars({
      [styles.contentBold]: isBold ? "bold" : "normal",
    })}
    {...rest}
  >
    <span className={styles.linkText}>{children}</span>
    {icon && <span className={styles.suffixOuter}>{icon}</span>}
  </a>
);
