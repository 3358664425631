import { FC } from "react";
import { useInView } from "react-intersection-observer";
import { AppOsId } from "@root/shared/types/type";
import { Presentation } from "./Presentation";

type Props = {
  onCancel: () => void;
  appOsType: AppOsId;
};

export const SaveAndCancelButtonArea: FC<Props> = ({ onCancel, appOsType }) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });
  // 要素がviewport内になく、Androidではない
  const isFixed = !inView && appOsType !== 2;

  return <Presentation elementRef={ref} isFixed={isFixed} onCancel={onCancel} />;
};
