import { FC, useState, useRef, useEffect } from "react";
import { StyledElements } from "./src/styled_elements";

export type ShopAccordionProps = {
  totalNumber: number;
  shopInformations: {
    detailUrlWithShopId: string;
    name: string;
    address: string;
    nearestStation: string;
  }[];
  gaAction: string;
  gaLabels: string[];
  isSp: boolean;
  query: string;
};

export const ShopAccordion: FC<ShopAccordionProps> = ({
  totalNumber,
  shopInformations,
  gaAction,
  gaLabels,
  isSp,
  query,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refs = useRef<HTMLLIElement[]>([]);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (refs.current) {
      if (isSp) {
        setHeight(refs.current[0].clientHeight);
      } else {
        const paddingHeight = 12;
        const shopInformationsHeight =
          refs.current[0].clientHeight +
          refs.current[1].clientHeight +
          refs.current[2].clientHeight;
        setHeight(shopInformationsHeight + paddingHeight);
      }
    }
  }, [isSp]);

  return (
    <StyledElements isOpen={isOpen} height={height}>
      <div className="accordion-container">
        <div className="accordion-outer">
          <ul>
            {shopInformations.map(
              ({ detailUrlWithShopId, name, address, nearestStation }, index) => (
                <li
                  key={detailUrlWithShopId}
                  ref={(element: HTMLLIElement) => (refs.current[index] = element)}
                  className="main-table-list"
                >
                  <span>
                    <a
                      href={detailUrlWithShopId}
                      className="js-add-query-strings ga-click"
                      data-ga-action={gaAction}
                      data-ga-label={gaLabels[index]} // gaLabelとshopInformationsのindexが一致している前提は危ない.設計再検討したい.
                      data-query-str={query}
                    >
                      {name}
                    </a>
                  </span>
                  <span>
                    {address} {nearestStation && `(${nearestStation})`}
                  </span>
                </li>
              ),
            )}
            {totalNumber > 6 && <span className="shop-remining">...他</span>}
          </ul>
        </div>
        <button className="acoordion-button" type="button" onClick={toggleAccordion}>
          {isOpen ? "その他の店舗を閉じる" : "その他の店舗を見る"}
          <span className={`accordion-arrow ${isOpen ? "is-active" : ""}`}>
            <i className="icon-down-open-big" />
          </span>
        </button>
      </div>
    </StyledElements>
  );
};
