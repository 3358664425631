import { FC, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Pulldown, ValidationErrorMessage } from "@legacy_user_frontend/components/ui_parts";
import {
  CassetteProps,
  LineListResponseProps,
  StationListResponseProps,
  StationGroupResponseProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/src/profiles_edit_props";
import { arrayToObjects } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/src/modules";
import { DeletableCassette } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/deletable_cassette";
import { StyledErrorMessageOuter } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/src/styled_elements";
import styled from "styled-components";
import { FetchJson } from "@root/utils/fetchJson";
import { Colors } from "@legacy_user_frontend/utils";
import { sendErrorLog } from "@root/utils/sendErrorLog";

type StyledLineAndStationOuterProps = {
  isSelected: boolean;
};

const StyledLineAndStationOuter = styled.div<StyledLineAndStationOuterProps>`
  /* 駅と路線を選択済みの場合はフォームを非表示にする */
  display: ${({ isSelected }) => (isSelected ? "none" : "flex")};
  gap: 8px;

  > select {
    flex-basis: 44%;

    &:first-of-type {
      flex-basis: 66%;
    }
  }
`;

const StyledStationList = styled.ul`
  list-style-type: none;
  line-height: 1.5;
  font-size: 14px;
  color: ${Colors.LIGHT_BLACK};
`;

export const StationCassette: FC<CassetteProps> = ({
  cassetteIndex,
  lineList,
  stationList,
  remove,
  fetchStationsListPath,
  fetchStationsGroupPath,
  fetchLinesListPath,
}) => {
  const cassetteNumber = 1 + cassetteIndex;
  const cassetteFormName = `form_user_frontend_user_update[stations_attributes][${cassetteIndex}]`;
  const { formState, setValue, resetField, watch, clearErrors } = useFormContext();
  const validateErrors = formState.errors.form_user_frontend_user_update?.stations_attributes;
  const stationErrors = validateErrors && validateErrors[`${cassetteIndex}`];

  // 都道府県の変更を駅・路線に反映する
  const watchedPrefectureId = watch("form_user_frontend_user_update[prefecture_id]");
  const [selectedLineList, setSelectedLineList] = useState(lineList);
  useEffect(() => {
    if (!watchedPrefectureId) {
      return;
    }
    (async () => {
      const res = await FetchJson<LineListResponseProps>({
        url: fetchLinesListPath,
        params: {
          prefecture: watchedPrefectureId,
        },
        handleError: (error?: Error) => {
          sendErrorLog(new Error(error?.message || "路線の取得に失敗しました"));
        },
      });
      if (!res) {
        return;
      }
      setSelectedLineList(arrayToObjects(res));
    })();
  }, [fetchLinesListPath, watchedPrefectureId]);

  // 路線の変更を駅に反映する
  const watchedLineId = watch(`${cassetteFormName}[line_id]`);
  const watchedStationId = watch(`${cassetteFormName}[station_id]`);
  const [selectedStationList, setSelectedStationList] = useState(stationList);
  useEffect(() => {
    if (!watchedLineId) {
      setSelectedStationList([]);
      return;
    }
    (async () => {
      const res = await FetchJson<StationListResponseProps>({
        url: fetchStationsListPath,
        params: {
          line: watchedLineId,
        },
        handleError: (error?: Error) => {
          sendErrorLog(new Error(error?.message || "駅の取得に失敗しました"));
        },
      });
      if (!res) {
        return;
      }
      setSelectedStationList(arrayToObjects(res));
    })();
  }, [fetchStationsListPath, cassetteFormName, resetField, watchedLineId]);
  useEffect(() => {
    const isSelectable = selectedStationList.filter(({ value }) => {
      if (value === watchedStationId) {
        return true;
      }
      return false;
    });
    if (watchedStationId && isSelectable) {
      setValue(`${cassetteFormName}[station_id]`, watchedStationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStationList]);

  // 駅と路線を選択したら、最寄駅リストを表示する
  const [fetchedStationsGroup, setFetchedStationsGroup] = useState<string[]>([]);
  useEffect(() => {
    if (!watchedStationId) {
      setFetchedStationsGroup([]);
      return;
    }
    (async () => {
      const res = await FetchJson<StationGroupResponseProps>({
        url: fetchStationsGroupPath,
        params: {
          station: watchedStationId,
        },
        handleError: (error?: Error) => {
          sendErrorLog(new Error(error?.message || "最寄駅の取得に失敗しました"));
        },
      });
      if (!res) {
        setFetchedStationsGroup([]);
        return;
      }
      setFetchedStationsGroup(res);
    })();
  }, [fetchStationsGroupPath, watchedStationId]);

  // 路線をの場合は駅プルダウンを非活性にする
  const [isStationEntered, setIsStationEntered] = useState(true);
  useEffect(() => {
    if (watchedLineId) {
      setIsStationEntered(true);
    } else {
      setIsStationEntered(false);
    }
  }, [watchedLineId]);

  // 削除処理
  const handleOnClick = () => {
    remove(cassetteIndex);
    clearErrors("form_user_frontend_user_update[stations]");
  };

  return (
    <DeletableCassette heading={`最寄り駅${cassetteNumber}`} handleOnClick={handleOnClick}>
      <>
        <StyledLineAndStationOuter
          isSelected={
            // 駅と路線が選択済み（最寄駅リストがある）の場合はtrue
            !!(fetchedStationsGroup.length > 0)
          }
        >
          <Pulldown
            name={`${cassetteFormName}[line_id]`}
            placeholder="路線を選択"
            list={selectedLineList}
          />
          <Pulldown
            name={`${cassetteFormName}[station_id]`}
            requiredMessage="最寄り駅を選択してください"
            placeholder="駅を選択"
            list={selectedStationList}
            isDisabled={!isStationEntered}
          />
        </StyledLineAndStationOuter>
        {fetchedStationsGroup.length > 0 && (
          <StyledStationList>
            {fetchedStationsGroup.map((station: string) => (
              <li key={station}>{station}</li>
            ))}
          </StyledStationList>
        )}
        {stationErrors && (
          <StyledErrorMessageOuter>
            {stationErrors.line_id?.message && (
              <ValidationErrorMessage message={stationErrors.line_id.message} />
            )}
            {stationErrors.station_id?.message && (
              <ValidationErrorMessage message={stationErrors.station_id.message} />
            )}
          </StyledErrorMessageOuter>
        )}
      </>
    </DeletableCassette>
  );
};
