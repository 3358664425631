import { FC } from "react";
import { SectionTitle } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SectionTitle";
import { SectionWithTitleAndLabel } from "@userFrontend/components/SectionWithTitleAndLabel";
import { ErrorInformationText } from "@userFrontend/components/ErrorInformationText";
import { TextAreaWithWordCounter } from "@userFrontend/components/TextAreaWithWordCounter";
import * as styles from "./styles.css";
// types
import { Props } from "./type";

export const WorkplaceReviewThought: FC<Props> = ({
  handleTextAreaChange,
  questions,
  workplaceMatchingRef,
  interestRewardRef,
  challengeRef,
  expectationRef,
  textAreaValues,
  errorTexts,
}) => {
  const getRefByCategoryName = (categoryName: string): React.RefObject<HTMLDivElement> | null => {
    switch (categoryName) {
      case "workplaceMatching":
        return workplaceMatchingRef;
      case "interestReward":
        return interestRewardRef;
      case "challenge":
        return challengeRef;
      case "expectation":
        return expectationRef;
      default:
        return null;
    }
  };
  return (
    <div className={styles.sectionOuter}>
      <SectionTitle text="職場の口コミ（あなたの考え/想い）" />
      <div className={styles.container}>
        {Object.entries(questions).map(([categoryName, question]) => (
          <div key={categoryName} className={styles.questionOuter}>
            <SectionWithTitleAndLabel
              labelType="required"
              title={question.questionText}
              containerRef={getRefByCategoryName(categoryName)}
            >
              <TextAreaWithWordCounter
                value={textAreaValues?.[categoryName] ?? ""}
                id={`textarea-${question.questionId}`}
                handleTextAreaChange={handleTextAreaChange}
                placeholder={question.placeholder}
                size="large"
                hasError={!!errorTexts?.[categoryName]}
                categoryName={categoryName}
              />
              {errorTexts?.[categoryName] && (
                <ErrorInformationText text={errorTexts[categoryName] || ""} />
              )}
            </SectionWithTitleAndLabel>
          </div>
        ))}
      </div>
    </div>
  );
};
