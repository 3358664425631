import { ComponentProps, FC, useEffect, useState } from "react";
import { axiosPost } from "@root/utils/axiosForRails";
import { useHistoryBack } from "@userFrontend/hooks/useHistoryBack";
import { AxiosError } from "axios";
import { Presentation } from "./Presentation";
import { JobEntryRadioButton } from "./uiParts/JobEntryRadioButton";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

type Props = {
  initialCheckedJobEntryId: number | null;
  jobEntryButtons: Pick<
    ComponentProps<typeof JobEntryRadioButton>,
    "clientName" | "brandName" | "workTitle" | "jobEntryId" | "disabled"
  >[];
  userContactUrl: string;
  schedulePromotionEmailUrl: string;
  jobEntryIdsWithFalsityPotential: number[];
  userId: number;
  siteId: 1 | 2;
  isSp: boolean;
  isError: boolean;
};

export const JobEntryRadioButtonsWithModal: FC<Props> = ({
  initialCheckedJobEntryId,
  jobEntryButtons,
  userContactUrl,
  schedulePromotionEmailUrl,
  jobEntryIdsWithFalsityPotential,
  userId,
  siteId,
  isSp,
  isError,
}) => {
  const [checkedJobEntryId, setCheckedJobEntryId] = useState<number | null>(
    initialCheckedJobEntryId,
  );
  const [showModal, setShowModal] = useState(false);
  const checkedJobEntryWithFalsityPotential =
    !!checkedJobEntryId && jobEntryIdsWithFalsityPotential.includes(checkedJobEntryId);
  const isExit = useHistoryBack(!checkedJobEntryWithFalsityPotential);

  useEffect(() => {
    if (!isExit) {
      return;
    }

    setShowModal(true);
  }, [isExit]);

  useEffect(() => {
    const statePresent = window && window.history.state && window.history.state.isExit === false;
    const historyBack = () => {
      window.history.go(-1);
    };
    if (statePresent && checkedJobEntryWithFalsityPotential) {
      window.removeEventListener("popstate", historyBack);
    } else {
      window.addEventListener("popstate", historyBack);
    }
    return () => {
      window.removeEventListener("popstate", historyBack);
    };
  }, [isExit, checkedJobEntryWithFalsityPotential]);

  const changeJobEntryHandler = async (jobEntryId: number) => {
    setCheckedJobEntryId(jobEntryId);
    // NOTE: 離脱したユーザー向けに、勤続支援金申請の訴求メールを送信予約する処理をサーバ側で実行します。
    await axiosPost
      .post(schedulePromotionEmailUrl, {
        schedule_promotion_email: {
          user_id: userId,
          site_id: siteId,
          job_entry_id: jobEntryId,
        },
      })
      .catch((error: AxiosError) => {
        if (typeof newrelic !== "undefined") {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          newrelic.noticeError(error.message);
        }
      });
  };

  const modalCloseHandler = () => {
    setShowModal(false);
    window.history.back();
  };

  const modalProps = {
    showModal,
    closeHandler: modalCloseHandler,
    userContactUrl,
  };

  const radioButtonsProps = {
    jobEntryButtons,
    checkedJobEntryId,
    isSp,
    isError,
    userContactUrl,
    changeJobEntryHandler,
  };

  return <Presentation {...modalProps} {...radioButtonsProps} />;
};
