import { ComponentProps, useCallback, useState } from "react";
import { FetchData } from "@root/utils/fetchData";
import { useEntryLink } from "@userFrontend/hooks/useEntryLink";
import { useSalonTourButton } from "@userFrontend/hooks/useSalonTourButton";
import { useExtendScoutDeadlineButton } from "@userFrontend/hooks/useExtendScoutDeadlineButton";
import { addQueryStrings } from "@userFrontend/utils/addQueryStrings";
import { Presentation } from "./Presentation";
import { FetchedData } from "./type";

export const useWorkDetailBottomButtons = (
  fetchUrl: string,
  recommendFetchUrl: string,
): ComponentProps<typeof Presentation> | undefined => {
  const gaPrefix = "detail";
  const position = "bottom";

  const [isShowAfterKeepRecommend, setIsShowAfterKeepRecommend] = useState(false);

  const { handleClickEntryLink } = useEntryLink();
  const { handleClickSalonTourButton } = useSalonTourButton();
  const { handleClickExtendScoutDeadlineButton } = useExtendScoutDeadlineButton();

  const handleClickKeepForm = useCallback(
    (event: React.MouseEvent<HTMLElement>, isApp: boolean) => {
      // すでにキープ押下後のレコメンドが表示済み あるいは アプリwebview
      if (isShowAfterKeepRecommend || isApp) return;

      // キープ押下後レコメンド表示時にスクロールする位置を取るため
      const element = document
        .getElementById("after-keep-scroll-to-target-element")
        ?.getBoundingClientRect();
      let scrollPosition;
      if (element) {
        const TAB_HEIGHT = 56;
        scrollPosition = element.top + window.pageYOffset + TAB_HEIGHT;
      }

      // キープボタンは"1"、解除ボタンは"2"
      const keepButtonStatus = event.currentTarget
        ?.closest("[data-bookmark-status]")
        ?.getAttribute("data-bookmark-status");

      // キープ押下時のみ表示し、スクロールする
      if (keepButtonStatus === "1") {
        setIsShowAfterKeepRecommend(true);
        window.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    },
    [isShowAfterKeepRecommend],
  );

  const createData = useCallback(
    (fetchedData: FetchedData) => {
      const entryUrlWithQuery = addQueryStrings(
        fetchedData.buttonContents.entryButtonContent.entryUrl,
        fetchedData.queryStr,
      );

      const salonTourEntryUrlWithQuery = addQueryStrings(
        fetchedData.buttonContents.salonTourButtonContent.salonTourEntryUrl,
        fetchedData.queryStr,
      );

      // APPの場合は何もパラメータを付けない
      const salonTourButtonUrl = fetchedData.isApp
        ? fetchedData.buttonContents.salonTourButtonContent.salonTourEntryUrl
        : salonTourEntryUrlWithQuery;

      return {
        recommendFetchUrl,
        isShowAfterKeepRecommend,
        queryStr: fetchedData.queryStr,
        workCode: fetchedData.workCode,
        clientShopId: fetchedData.clientShopId,
        capitalizePageType: fetchedData.capitalizePageType,
        isPreview: fetchedData.isPreview,
        isSp: fetchedData.isSp,
        isApp: fetchedData.isApp,
        entryProps: {
          isEnterable: fetchedData.buttonContents.entryButtonContent.isEnterable,
          hasEntried: fetchedData.buttonContents.entryButtonContent.hasEntried,
          firebaseAction: "entry",
          reproAction: `trackTapJob${fetchedData.capitalizePageType}Entry`,
          /* eslint no-script-url: 0 */
          url: fetchedData.isPreview ? "javascript:void(0)" : entryUrlWithQuery,
          gaAction: fetchedData.buttonContents.scoutButtonContent.isScouted
            ? `${gaPrefix}_scout_entry${position}`
            : `${gaPrefix}_entry${position}`,
          gaLabel: fetchedData.gaLabel,
          onClick: () => handleClickEntryLink(`${gaPrefix}_entry${position}`, fetchedData.gaLabel),
        },
        keepProps: {
          hasKeeped: fetchedData.buttonContents.keepButtonContent.hasKeeped,
          isShowKeepBalloon: false, // 求人詳細下部ボタンでは一律で表示しないため
          isShowConfirmRemoveKeepModal:
            fetchedData.buttonContents.keepButtonContent.isShowConfirmRemoveKeepModal,
          queryStr: `client_shop_id=${fetchedData.clientShopId}`,
          /* eslint no-script-url: 0 */
          url: fetchedData.isPreview
            ? "javascript:void(0)"
            : fetchedData.buttonContents.keepButtonContent.keepButtonUrl,
          gaAction: `${gaPrefix}_keep-${position}`,
          gaLabel: fetchedData.gaLabel,
          onClick: (event: React.MouseEvent<HTMLElement>) =>
            fetchedData.isPreview ? undefined : handleClickKeepForm(event, fetchedData.isApp),
        },
        salonTourProps: {
          isSalonTour: fetchedData.buttonContents.salonTourButtonContent.isSalonTour,
          /* eslint no-script-url: 0 */
          url: fetchedData.isPreview ? "javascript:void(0)" : salonTourButtonUrl,
          onClick: () =>
            handleClickSalonTourButton(
              fetchedData.isApp,
              fetchedData.workCode,
              fetchedData.buttonContents.scoutButtonContent.isScouted,
              fetchedData.clientShopId,
              fetchedData.buttonContents.scoutButtonContent.isScouted
                ? `${gaPrefix}_scout_entry_tour-${position}`
                : `${gaPrefix}_entry_tour-${position}`,
              fetchedData.gaLabel,
              fetchedData.buttonContents.salonTourButtonContent.salonTourEntryUrl,
            ),
        },
        telEntryProps: {
          isShowTelButton: fetchedData.telNumberContent.isShowTellButton,
          telNumber: fetchedData.telNumberContent.telNumber,
          gaLabel: fetchedData.gaLabel,
        },
        scoutProps: {
          isScouted: fetchedData.buttonContents.scoutButtonContent.isScouted,
          scoutClientShopId: fetchedData.buttonContents.scoutButtonContent.scoutClientShopId,
          url: fetchedData.buttonContents.scoutButtonContent.scoutedEntryUrl,
        },
        extendScoutProps: {
          isScoutExtend: fetchedData.buttonContents.scoutButtonContent.isScoutExtend,
          scoutDeadline: fetchedData.buttonContents.scoutButtonContent.scoutDeadline,
          url: fetchedData.buttonContents.scoutButtonContent.extendScoutUrl,
          gaAction: `${gaPrefix}_scout_extend-${position}`,
          gaLabel: fetchedData.gaLabel,
          onClick: () =>
            handleClickExtendScoutDeadlineButton(
              fetchedData.buttonContents.scoutButtonContent.extendScoutUrl || "",
              `${gaPrefix}_scout_entry_tour-${position}`,
              fetchedData.gaLabel,
            ),
        },
        declineScoutProps: {
          url: fetchedData.buttonContents.scoutButtonContent.declineScoutUrl,
          gaAction: `${gaPrefix}_scout_decline-${position}`,
          gaLabel: fetchedData.gaLabel,
        },
      };
    },
    [
      recommendFetchUrl,
      isShowAfterKeepRecommend,
      handleClickKeepForm,
      handleClickEntryLink,
      handleClickSalonTourButton,
      handleClickExtendScoutDeadlineButton,
    ],
  );

  const data = FetchData<{ data: FetchedData }>(fetchUrl);

  const formattedData = data ? createData(data.data) : undefined;

  return formattedData;
};
