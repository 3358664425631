import { FC, ComponentProps } from "react";
import { createPortal } from "react-dom";
import { DetailPageActionButtons } from "@userFrontend/features/publication/components/DetailPageActionButtons";
import { AfterKeepRecommendWorks } from "@userFrontend/features/publication/components/AfterKeepRecommendWorks";
import { Buttons } from "./uiParts/Buttons";

type Props = ComponentProps<typeof DetailPageActionButtons> & {
  recommendFetchUrl: string;
  isShowAfterKeepRecommend: boolean;
};

export const Presentation: FC<Props> = ({
  recommendFetchUrl,
  isShowAfterKeepRecommend,
  workCode,
  clientShopId,
  isApp,
  isSp,
  isPreview,
  capitalizePageType,
  entryProps,
  keepProps,
  salonTourProps,
  telEntryProps,
  scoutProps,
  extendScoutProps,
  declineScoutProps,
}) => {
  const potalPosition = document.getElementById("after-keep-recommend-portal");

  return (
    <div id="after-keep-scroll-to-target-element">
      <Buttons
        verticallyStyle={isSp} // PCとSPで要素・スタイルを分ける必要があるため
        workCode={workCode}
        clientShopId={clientShopId}
        isApp={isApp}
        isSp={isSp}
        isPreview={isPreview}
        capitalizePageType={capitalizePageType}
        entryProps={entryProps}
        keepProps={keepProps}
        salonTourProps={salonTourProps}
        telEntryProps={telEntryProps}
        scoutProps={scoutProps}
        extendScoutProps={extendScoutProps}
        declineScoutProps={declineScoutProps}
      />
      {isShowAfterKeepRecommend &&
        potalPosition &&
        !isApp && // NOTE:「アプリwebviewなおかつ非ログイン」の際にキープ状態が反映されない状態で、解消にはアプリ側修正が必要なため、暫定的にアプリでは非表示とする。
        createPortal(<AfterKeepRecommendWorks fetchUrl={recommendFetchUrl} />, potalPosition)}
    </div>
  );
};
