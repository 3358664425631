import { FC, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  Pulldown,
  TextField,
  ValidationErrorMessage,
} from "@legacy_user_frontend/components/ui_parts";
import styled from "styled-components";
import {
  AddressFormProps,
  AddressResponseProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/src/profiles_edit_props";
import { PrivateLabel } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/private_label";
import { arrayToObjects } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/src/modules";
import { StyledErrorMessageOuter } from "@legacy_user_frontend/components/pages/mypage/profiles/profiles_edit/src/styled_elements";
import { FetchJson } from "@root/utils/fetchJson";
import { sendErrorLog } from "@root/utils/sendErrorLog";

const StyledPrefectureAndCityOuter = styled.div`
  display: flex;
  gap: 8px;

  > select {
    flex-basis: 50%;
  }
`;

const StyledPrivateLabelOuter = styled.div`
  padding-top: 8px;
  text-align: right;
`;

const StyledAddressOuter = styled.div`
  padding-top: 8px;
`;

export const Address: FC<AddressFormProps> = ({
  prefectureList,
  selectedCityList,
  setSelectedCityList,
  resetLinesAndStations,
  fetchCitiesListPath,
  fetchedCityId,
  setFetchedCityId,
}) => {
  // 親コンポーネントからuseFormフック関数の受け取り
  const { formState, resetField, setValue, watch } = useFormContext();
  const validateErrors = formState.errors;

  // 都道府県を変更したら市区町村リストを非同期で更新する
  const [isCityDisabled, setIsCityDisabled] = useState(false);
  const [isAddressDisabled, setIsAddressDisabled] = useState(false);
  const watchedPrefectureId = watch("form_user_frontend_user_update[prefecture_id]");
  const watchedCityId = watch("form_user_frontend_user_update[city_id]");
  const fetchCitiesList = async (prefectureId: string) => {
    const res = await FetchJson<AddressResponseProps>({
      url: fetchCitiesListPath,
      params: {
        prefecture: prefectureId,
      },
      handleError: (error?: Error) => {
        sendErrorLog(new Error(error?.message || "市区町村の取得に失敗しました"));
      },
    });
    if (!res) {
      return;
    }
    const fetchedCityList = arrayToObjects(res);
    if (JSON.stringify(selectedCityList) !== JSON.stringify(fetchedCityList)) {
      setSelectedCityList(fetchedCityList);
    }
    setValue("form_user_frontend_user_update[city_id]", fetchedCityId);
  };

  // 市区町村と駅・路線の選択を解除する
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    fetchCitiesList(event.target.value).then(() => {
      setFetchedCityId("");
      resetField("form_user_frontend_user_update[city_id]", { defaultValue: "" });
      resetLinesAndStations();
    });
  };

  // 都道府県が未選択の場合は市区町村プルダウンを非活性にする
  useEffect(() => {
    if (watchedPrefectureId) {
      setIsCityDisabled(false);
    } else {
      setIsCityDisabled(true);
    }
  }, [watchedPrefectureId]);

  // 市区町村が未選択の場合は町域・番地テキストフィールドを未入力に戻し非活性化する
  useEffect(() => {
    if (watchedCityId) {
      setIsAddressDisabled(false);
    } else {
      setIsAddressDisabled(true);
      resetField("form_user_frontend_user_update[address1]", { defaultValue: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetField, watchedCityId]);

  return (
    <>
      <StyledPrefectureAndCityOuter>
        <Pulldown
          name="form_user_frontend_user_update[prefecture_id]"
          placeholder="都道府県を選択"
          list={prefectureList}
          requiredMessage="都道府県を選択してください"
          isError={!!validateErrors.form_user_frontend_user_update?.prefecture_id?.message}
          onChange={handleOnChange}
        />
        <Pulldown
          name="form_user_frontend_user_update[city_id]"
          placeholder="市区町村を選択"
          list={selectedCityList}
          requiredMessage="市区町村を選択してください"
          isError={!!validateErrors.form_user_frontend_user_update?.city_id?.message}
          isDisabled={isCityDisabled}
        />
      </StyledPrefectureAndCityOuter>
      {validateErrors.form_user_frontend_user_update?.prefecture_id?.message && (
        <StyledErrorMessageOuter>
          <ValidationErrorMessage
            message={validateErrors.form_user_frontend_user_update.prefecture_id.message}
          />
        </StyledErrorMessageOuter>
      )}
      {validateErrors.form_user_frontend_user_update?.city_id?.message && (
        <StyledErrorMessageOuter>
          <ValidationErrorMessage
            message={validateErrors.form_user_frontend_user_update.city_id.message}
          />
        </StyledErrorMessageOuter>
      )}
      <StyledPrivateLabelOuter>
        <PrivateLabel />
      </StyledPrivateLabelOuter>
      <StyledAddressOuter>
        <TextField
          name="form_user_frontend_user_update[address1]"
          placeholder="町域・番地"
          isDisabled={isAddressDisabled}
        />
      </StyledAddressOuter>
    </>
  );
};
