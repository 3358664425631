import { ComponentProps, FC, useState, useEffect, Fragment } from "react";
import { PremiumBanner } from "./src/premium_banner";
import { Cassette } from "./src/cassette";
import { CassetteProps } from "./src/cassette/src/cassette_props";

declare const newrelic: any; // eslint-disable-line @typescript-eslint/no-explicit-any

type Props = {
  fetchUrl: string;
  dummyImgPath: string;
  login: boolean;
  isSp: boolean;
  keepedFlags: boolean[];
  premiumBanner?: PremiumBannerProps;
  cassetteType?: string;
  showAwardsLabelFlag?: boolean;
  isContractRunning: boolean;
};

type PremiumBannerProps = ComponentProps<typeof PremiumBanner>;

export const Cassettes: FC<Props> = ({
  fetchUrl,
  dummyImgPath,
  login,
  isSp,
  keepedFlags,
  premiumBanner,
  cassetteType,
  showAwardsLabelFlag,
  isContractRunning,
}) => {
  const [cassettes, setCassettes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(fetchUrl);
        const json = await res.json();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setCassettes(json.data);
      } catch (e) {
        if (typeof newrelic !== "undefined") {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          newrelic.noticeError(e);
        }
      }
    })();
  }, [fetchUrl]);

  return (
    <>
      {cassettes.map((cassette: CassetteProps["cassetteHash"], index) => (
        <Fragment key={cassette.workCode}>
          <Cassette
            cassetteHash={{
              ...cassette,
              searchEvents: {
                ...cassette.searchEvents,
                cassetteEvent: {
                  ...cassette.searchEvents.cassetteEvent,
                  cassetteSearchListRank: index + 1,
                },
              },
            }}
            dummyImgPath={dummyImgPath}
            login={login}
            isSp={isSp}
            keepedFlag={keepedFlags[index]}
            cassetteType={cassetteType}
            showAwardsLabelFlag={showAwardsLabelFlag}
            isContractRunning={isContractRunning}
          />
          {premiumBanner && index === 4 && (
            <PremiumBanner
              url={premiumBanner.url}
              gaAction={premiumBanner.gaAction}
              gaLabel={premiumBanner.gaLabel}
              query={premiumBanner.query}
              lazyLoadImage={premiumBanner.lazyLoadImage}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
