import { FC, useState, useEffect, useCallback, useRef } from "react";
import axios, { AxiosError } from "axios";
import { axiosPost } from "@root/utils/axiosForRails";
import { sendBeaconData, showAlert } from "@root/utils";
import { SiteId } from "@root/shared/types/type";
import { sendAppWebhook } from "@userFrontend/utils/sendAppWebhook";
import { IOS_APP_ID, ANDROID_APP_ID } from "@root/utils/constants";
import { Presentation } from "./Presentation";
// hooks
import { useValidationText } from "./useValidationText";
import { useFormatQuestion } from "./useFormatQuestion";
// types
import {
  Props,
  CheckboxState,
  RadioButtonState,
  TextAreaState,
  CurrentStep,
  Client,
  ValidationErrorStateProps,
  ErrorItem,
} from "./type";
import { InputNames } from "./utils/InputNames";
import { useSearchModal } from "./useSearchModal";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

// FormData型の定義
/* eslint-disable camelcase */
type BackupFormData = {
  form_user_frontend_client_review_create: {
    client_id: number | null;
    client_name: string | null;
    business_type_id: number | null;
    employment_status: number | null;
    continued_service_years: number | null;
    employment_id: number | null;
    atmosphere: number | null;
    salary_standard: number | null;
    debut: number | null;
    education: number | null;
    work_time: number | null;
    salary_change: number | null;
    workplace_matching: string | null;
    interest_reward: string | null;
    challenge: string | null;
    expectation: string | null;
    treatments: number[];
    cultures: number[];
  };
};

type RestoreData = {
  siteId: SiteId;
  clientId: number;
  clientName: string;
  businessTypeId: number;
  employmentStatus: number;
  continuedServiceYears: number;
  employmentId: number;
  atmosphere: number;
  salaryStandard: number;
  debut: number;
  education: number;
  workTime: number;
  salaryChange: number;
  workplaceMatching: string;
  interestReward: string;
  challenge: string;
  expectation: string;
  treatments: number[];
  cultures: number[];
};

type FormValueProps = {
  siteId: SiteId;
  client?: Client;
  employmentStatusValue?: number;
  continuedServiceYearsValue?: number;
  businessTypeValue?: number;
  employmentValue?: number;
  checkboxValues: CheckboxState;
  radioValues: RadioButtonState;
  textAreaState: TextAreaState;
};

type Response = {
  data: {
    errors: ErrorItem[];
  };
  status: number;
  statusText?: string;
};

const INPUT_STEP = 1;
const CONFIRMATION_STEP = 5;
// 送信完了時に送るwebhookの内容
const APP_EVENT_MESSAGE = {
  message: "ClientReview",
};

export const ClientReviewInputs: FC<Props> = ({
  initialSiteId,
  businessTypes,
  employments,
  selectiveQuestions,
  textAreaQuestions,
  clientReviewConfirmPath,
  clientReviewCreatePath,
  createBackupPath,
  restoreBackupPath,
  deleteBackupPath,
  termsPath,
  guidelinePath,
  clientSearchPath,
  userContactPath,
  mypageTopPath,
  completedRedirectPath,
}) => {
  const initialFormValues: FormValueProps = {
    siteId: initialSiteId,
    client: undefined,
    employmentStatusValue: undefined,
    continuedServiceYearsValue: undefined,
    businessTypeValue: undefined,
    employmentValue: undefined,
    checkboxValues: {},
    radioValues: {},
    textAreaState: {},
  };
  // 入力項目のstate
  const [formValues, setFormValues] = useState<FormValueProps>(initialFormValues);

  // 投稿先企業の検索の表示/日表示state
  const [isShowSearchModal, setIsShowSearchModal] = useState(false);

  // 入力値バックアップ関連のstate
  const [isShowBackupModal, setIsShowBackupModal] = useState<boolean>(false);

  // 画面ステップ管理のstate
  const [currentStep, setCurrentStep] = useState<CurrentStep>(INPUT_STEP);

  // Rails側バリデーションエラー文言とボタン押下時バリデーションエラーのstate
  const [validationError, setValidationError] = useState<ValidationErrorStateProps>({
    isFailed: false,
    railsValidationTexts: [],
  });

  const [isCompleted, setIsCompleted] = useState(false);

  // 投稿先企業選択
  const {
    isShowClientsDetail,
    clientNameValue,
    shopNameValue,
    searchResults,
    searchResultErrorText,
    isSearching,
    handleClientSearch,
    handleShowTextButton,
    handleChangeClientNameValue,
    handleChangeShopNameValue,
    handleReturnToMyPageTop,
  } = useSearchModal(mypageTopPath, clientSearchPath);

  // 扱いやすい形に整形するため
  const formattedQuestions = useFormatQuestion(
    formValues.siteId,
    employments,
    businessTypes,
    selectiveQuestions,
    textAreaQuestions,
  );

  // バリデーション判定
  const { errorTexts, isValid, updateClickStatus } = useValidationText(
    formValues.checkboxValues,
    formValues.radioValues,
    formValues.textAreaState,
    validationError.railsValidationTexts,
    formValues.client?.clientId,
    formValues.businessTypeValue,
    formValues.employmentValue,
    formValues.continuedServiceYearsValue,
  );

  const inputContainerRefs = useRef<{
    [key in (typeof InputNames)[number]]: React.RefObject<HTMLDivElement>;
  }>({
    client: useRef<HTMLDivElement>(null),
    businessTypeId: useRef<HTMLDivElement>(null),
    employmentStatus: useRef<HTMLDivElement>(null),
    continuedServiceYears: useRef<HTMLDivElement>(null),
    employmentId: useRef<HTMLDivElement>(null),
    atmosphere: useRef<HTMLDivElement>(null),
    salaryStandard: useRef<HTMLDivElement>(null),
    debut: useRef<HTMLDivElement>(null),
    education: useRef<HTMLDivElement>(null),
    workTime: useRef<HTMLDivElement>(null),
    salaryChange: useRef<HTMLDivElement>(null),
    workplaceMatching: useRef<HTMLDivElement>(null),
    interestReward: useRef<HTMLDivElement>(null),
    challenge: useRef<HTMLDivElement>(null),
    expectation: useRef<HTMLDivElement>(null),
    treatments: useRef<HTMLDivElement>(null),
    cultures: useRef<HTMLDivElement>(null),
  });

  // 最初のエラー箇所にスクロールする
  const scrollToFirstError = (firstErrorKey: string) => {
    const firstErrorInputContainerRef = inputContainerRefs.current[firstErrorKey];
    if (firstErrorInputContainerRef?.current) {
      firstErrorInputContainerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const isCurrentViewInput = useCallback((): boolean => {
    return currentStep === INPUT_STEP;
  }, [currentStep]);

  // 現在確認画面を表示しているかどうか
  const isCurrentViewConfirm = useCallback((): boolean => {
    return currentStep === CONFIRMATION_STEP;
  }, [currentStep]);

  // 投稿先変更ハンドラ
  const handleClientSelect = (selectedSiteId: SiteId, clientId: number, clientName: string) => {
    const isChangedSiteId = formValues.siteId !== selectedSiteId;
    // siteIdが変わる場合、siteIdによって項目が変化するものは選択内容を一度リセットする
    // 就業状況は別途初期値の選択を行うため、ここではリセットしない
    setFormValues({
      ...formValues,
      siteId: selectedSiteId,
      client: {
        clientId,
        clientName,
      },
      businessTypeValue: isChangedSiteId ? undefined : formValues.businessTypeValue,
      continuedServiceYearsValue: isChangedSiteId
        ? undefined
        : formValues.continuedServiceYearsValue,
      checkboxValues: isChangedSiteId ? {} : formValues.checkboxValues,
    });
    if (isChangedSiteId) {
      handleRadioChange("atmosphere", null);
      handleRadioChange("salaryStandard", null);
      handleRadioChange("debut", null);
      handleRadioChange("education", null);
      handleRadioChange("workTime", null);
      handleRadioChange("salaryChange", null);
    }
    setIsShowSearchModal(false);
  };

  // 投稿先企業選択モーダル表示/非表示のハンドラ
  const handleShowModal = () => setIsShowSearchModal(true);
  const handleCloseModal = () => setIsShowSearchModal(false);

  // 勤続年数変更ハンドラ
  const handleContinuedServiceYearSelect = (e: React.FocusEvent<HTMLSelectElement>) => {
    setFormValues({
      ...formValues,
      continuedServiceYearsValue: Number(e.target.value) || undefined,
    });
  };

  // 職種変更ハンドラ
  const handleBusinessTypeSelect = (e: React.FocusEvent<HTMLSelectElement>) => {
    setFormValues({
      ...formValues,
      businessTypeValue: Number(e.target.value) || undefined,
    });
  };

  // 雇用形態変更ハンドラ
  const handleEmploymentSelect = (e: React.FocusEvent<HTMLSelectElement>) => {
    setFormValues({
      ...formValues,
      employmentValue: Number(e.target.value) || undefined,
    });
  };

  // チェックボックス形式の変更ハンドラ(単一変更)
  const handleCheckboxChange = (category: string, optionId: number) => {
    setFormValues((prevState) => {
      // 現在のカテゴリの選択状態を取得（もしくは空の配列）
      const currentSelection = prevState.checkboxValues[category] || [];

      if (currentSelection.includes(optionId)) {
        // すでに選択されている場合は、その要素を削除
        return {
          ...prevState,
          checkboxValues: {
            ...prevState.checkboxValues,
            [category]: currentSelection.filter((id) => id !== optionId),
          },
        };
      }
      // 選択されていない場合は、その要素を追加
      return {
        ...prevState,
        checkboxValues: {
          ...prevState.checkboxValues,
          [category]: [...currentSelection, optionId],
        },
      };
    });
  };

  // チェックボックス形式の変更ハンドラ(複数変更)
  // バックアップの復元で利用
  const handleMultipleCheckboxChanges = (changes: Record<string, number[]>) => {
    setFormValues((prevState) => {
      let newCheckboxValues = { ...prevState.checkboxValues };

      Object.entries(changes).forEach(([category, ids]) => {
        ids.forEach((id: number) => {
          const currentSelection = newCheckboxValues[category] || [];
          if (currentSelection.includes(id)) {
            // 既に存在する場合は削除
            newCheckboxValues = {
              ...newCheckboxValues,
              [category]: currentSelection.filter((existingId) => existingId !== id),
            };
          } else {
            // 存在しない場合は追加
            newCheckboxValues = {
              ...newCheckboxValues,
              [category]: [...currentSelection, id],
            };
          }
        });
      });

      return {
        ...prevState,
        checkboxValues: newCheckboxValues,
      };
    });
  };

  const handleEmploymentStatusChange = (value?: number) => {
    if (!value) return;
    setFormValues((prevState) => ({
      ...prevState,
      employmentStatusValue: value,
      radioValues: {
        ...prevState.radioValues,
        employmentStatus: value,
      },
    }));
  };

  const handleRadioChange = (category: string, value: number | null) => {
    setFormValues((prevState) => ({
      ...prevState,
      radioValues: {
        ...prevState.radioValues,
        [category]: value,
      },
    }));
  };

  const handleTextAreaChange = (category: string, value: string) => {
    setFormValues((prevState) => ({
      ...prevState,
      textAreaState: {
        ...prevState.textAreaState,
        [category]: value,
      },
    }));
  };

  // 入力値バックアップのリストア処理
  const handleRestore = (data: RestoreData) => {
    setFormValues({
      ...formValues,
      businessTypeValue: data.businessTypeId,
      employmentValue: data.employmentId,
      continuedServiceYearsValue: data.continuedServiceYears,
      siteId: data.siteId,
      client: {
        clientId: data.clientId,
        clientName: data.clientName,
      },
    });
    handleEmploymentStatusChange(data.employmentStatus);
    handleRadioChange("atmosphere", data.atmosphere);
    handleRadioChange("salaryStandard", data.salaryStandard);
    handleRadioChange("debut", data.debut);
    handleRadioChange("education", data.education);
    handleRadioChange("workTime", data.workTime);
    handleRadioChange("salaryChange", data.salaryChange);
    handleTextAreaChange("workplaceMatching", data.workplaceMatching);
    handleTextAreaChange("interestReward", data.interestReward);
    handleTextAreaChange("challenge", data.challenge);
    handleTextAreaChange("expectation", data.expectation);
    const backupTreatments: Record<string, number[]> = { treatments: data.treatments };
    handleMultipleCheckboxChanges(backupTreatments);
    const backupCultures: Record<string, number[]> = { cultures: data.cultures };
    handleMultipleCheckboxChanges(backupCultures);
  };

  // 美容/ケアに応じて就業状況の初期値を設定する
  useEffect(() => {
    handleEmploymentStatusChange(
      formattedQuestions.basicAndEmploymentQuestions.employmentStatus?.filter(
        (item) => item.optionText === "現職",
      )[0].optionId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedQuestions.basicAndEmploymentQuestions.employmentStatus]);

  // 入力値バックアップ作成処理
  useEffect(() => {
    const saveFormData = (formData: BackupFormData) => {
      sendBeaconData(createBackupPath, formData);
    };

    // 入力値バックアップformパラメータ
    const formData = {
      form_user_frontend_client_review_create: {
        site_id: formValues.siteId,
        client_id: formValues.client?.clientId ?? null,
        client_name: formValues.client?.clientName ?? "",
        business_type_id: formValues.businessTypeValue ?? null,
        employment_status: formValues.radioValues.employmentStatus ?? null,
        continued_service_years: formValues.continuedServiceYearsValue ?? null,
        employment_id: formValues.employmentValue ?? null,
        atmosphere: formValues.radioValues.atmosphere ?? null,
        salary_standard: formValues.radioValues.salaryStandard ?? null,
        debut: formValues.radioValues.debut ?? null,
        education: formValues.radioValues.education ?? null,
        work_time: formValues.radioValues.workTime ?? null,
        salary_change: formValues.radioValues.salaryChange ?? null,
        workplace_matching: formValues.textAreaState.workplaceMatching ?? "",
        interest_reward: formValues.textAreaState.interestReward ?? "",
        challenge: formValues.textAreaState.challenge ?? "",
        expectation: formValues.textAreaState.expectation ?? "",
        treatments: formValues.checkboxValues.treatments
          ? formValues.checkboxValues.treatments
          : [],
        cultures: formValues.checkboxValues.cultures ? formValues.checkboxValues.cultures : [],
      },
    };

    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "hidden" &&
        (isCurrentViewInput() || (isCurrentViewConfirm() && !isCompleted))
      ) {
        saveFormData(formData);
      }
    };

    // イベントリスナーの設定
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // useEffectのクリーンアップ
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [createBackupPath, formValues, isCurrentViewInput, isCurrentViewConfirm, isCompleted]);

  // 入力値バックアップの存在有無確認
  useEffect(() => {
    const checkBackup = async () => {
      await axiosPost
        .post(restoreBackupPath)
        .then((response) => {
          // 存在する場合200、しない場合204
          if (response.status === 200) {
            setIsShowBackupModal(true);
          }
        })
        .catch((error: AxiosError) => {
          if (typeof newrelic !== "undefined") {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            newrelic.noticeError(error.message);
          }
        });
    };

    checkBackup();
  }, [restoreBackupPath]);

  // 入力値バックアップの削除
  const deleteBackup = async () => {
    await axiosPost.post(deleteBackupPath).catch((error: AxiosError) => {
      if (typeof newrelic !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        newrelic.noticeError(error.message);
      }
    });
  };

  // 送信処理
  const handleClickSubmitButton = async () => {
    // 確認画面の場合はバリデーションと送信、確認画面でなければバリデーションのみ実行するため
    const postPath = isCurrentViewConfirm() ? clientReviewCreatePath : clientReviewConfirmPath;

    const postData = {
      form_user_frontend_client_review_create: {
        client_id: formValues.client?.clientId ?? "",
        business_type_id: formValues.businessTypeValue ?? "",
        employment_status: formValues.employmentStatusValue ?? "",
        continued_service_years: formValues.continuedServiceYearsValue ?? "",
        employment_id: formValues.employmentValue ?? "",
        atmosphere: formValues.radioValues.atmosphere ?? "",
        salary_standard: formValues.radioValues.salaryStandard ?? "",
        debut: formValues.radioValues.debut ?? "",
        education: formValues.radioValues.education ?? "",
        work_time: formValues.radioValues.workTime ?? "",
        salary_change: formValues.radioValues.salaryChange ?? "",
        workplace_matching: formValues.textAreaState.workplaceMatching ?? "",
        interest_reward: formValues.textAreaState.interestReward ?? "",
        challenge: formValues.textAreaState.challenge ?? "",
        expectation: formValues.textAreaState.expectation ?? "",
        treatments: formValues.checkboxValues.treatments
          ? formValues.checkboxValues.treatments
          : [],
        cultures: formValues.checkboxValues.cultures ? formValues.checkboxValues.cultures : [],
      },
    };

    updateClickStatus();

    if (!isValid) {
      setValidationError((prevState) => ({ ...prevState, isFailed: true }));
      return;
    }

    const formData = new FormData();
    Object.entries(postData.form_user_frontend_client_review_create).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((subValue) => {
          formData.append(`form_user_frontend_client_review_create[${key}][]`, String(subValue));
        });
      } else {
        formData.append(`form_user_frontend_client_review_create[${key}]`, String(value));
      }
    });

    try {
      await axiosPost.post(postPath, formData);
      // 入力画面から確認画面へ遷移
      if (isCurrentViewInput()) {
        setCurrentStep(CONFIRMATION_STEP);
        // 確認画面から完了画面へ遷移
      } else if (isCurrentViewConfirm()) {
        // 投稿完了
        deleteBackup();
        setIsCompleted(true);
        // アプリwebviewの場合、iOSであれば"1"、Androidであれば"2"が入る
        const { mobileApp } = document.getElementsByTagName("body")[0].dataset;
        // アプリwebviewの場合はwebhookを送り、リダイレクトはしない
        if (mobileApp === String(IOS_APP_ID) || mobileApp === String(ANDROID_APP_ID)) {
          sendAppWebhook({
            iosEventMessage: APP_EVENT_MESSAGE,
            androidEventName: "ClientReview",
            androidEventMessage: JSON.stringify(APP_EVENT_MESSAGE),
          });
          return;
        }
        handleCompletedRedirect();
      }
    } catch (error) {
      if (!axios.isAxiosError(error) || !error.response) {
        showAlert("送信に失敗しました。時間をおいて再度お試しください。");
        return;
      }

      const response: Response = error.response as Response;
      switch (response.status) {
        case 400:
          if (response.data.errors) {
            // Rails側バリデーションエラーをerrorTextsに一括反映
            setValidationError((prevState) => ({
              ...prevState,
              railsValidationTexts: response.data.errors,
            }));
            // 確認画面でサーバーサイドエラーが返却された場合、入力画面へ戻す
            if (isCurrentViewConfirm()) {
              setCurrentStep(INPUT_STEP);
            }
            // 最初のエラー箇所にスクロール
            const firstErrorKey: string = Object.keys(response.data.errors[0])[0];
            scrollToFirstError(firstErrorKey);
          }
          break;
        default:
          if (typeof newrelic !== "undefined") {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            newrelic.noticeError(response.statusText);
          }
          showAlert("送信に失敗しました。時間をおいて再度お試しください。");
      }
    }
  };

  // 画面に遷移時に画面上部から表示
  useEffect(() => {
    if (isValid) {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  // ボタン押下時にエラーがあった場合は、エラーメッセージをもつ最初の項目へスクロールする
  useEffect(() => {
    if (
      !validationError.isFailed ||
      Object.values(errorTexts).filter((item) => item !== "").length === 0
    )
      return;

    let firstNonEmptyKey = Object.keys(errorTexts).find((key) => errorTexts[key]);
    // inputContainerRefsと一致させるため
    if (firstNonEmptyKey === "businessType") {
      firstNonEmptyKey = "businessTypeId";
    }
    if (firstNonEmptyKey === "employment") {
      firstNonEmptyKey = "employmentId";
    }
    if (firstNonEmptyKey) {
      // errorTextの中で、エラーメッセージをもつ最初のキーの項目へスクロールする
      scrollToFirstError(firstNonEmptyKey);
    }
    setValidationError((prevState) => ({ ...prevState, isFailed: false }));
  }, [errorTexts, validationError.isFailed]);

  const handleClickBackButton = () => {
    setIsShowBackupModal(false);
    setCurrentStep(INPUT_STEP);
  };

  // 投稿完了後に指定のpathへリダイレクトする
  const handleCompletedRedirect = () => {
    window.location.href = completedRedirectPath;
  };

  return (
    <Presentation
      currentStep={currentStep}
      questions={formattedQuestions}
      errorTexts={errorTexts}
      // BasicAndEmployment 基本情報(投稿先)
      client={formValues.client}
      handleShowModal={handleShowModal}
      isShowSearchModal={isShowSearchModal}
      handleCloseModal={handleCloseModal}
      handleClientSearch={handleClientSearch}
      clientNameValue={clientNameValue}
      shopNameValue={shopNameValue}
      handleChangeClientNameValue={handleChangeClientNameValue}
      handleChangeShopNameValue={handleChangeShopNameValue}
      searchResults={searchResults}
      handleClientSelect={handleClientSelect}
      isShowClientsDetail={isShowClientsDetail}
      handleShowTextButton={handleShowTextButton}
      searchResultErrorText={searchResultErrorText}
      displaySearchText={isSearching || searchResults.length === 0}
      isSearching={isSearching}
      handleReturnToMyPageTop={handleReturnToMyPageTop}
      userContactPath={userContactPath}
      // BasicAndEmployment 就業情報(就業状況)
      employmentStatusValue={formValues.employmentStatusValue || undefined}
      handleEmploymentStatusChange={(value) => handleEmploymentStatusChange(value)}
      // BasicAndEmployment 就業情報(勤続年数)
      continuedServiceYearsValue={formValues.continuedServiceYearsValue || undefined}
      handleContinuedServiceYearSelect={handleContinuedServiceYearSelect}
      // BasicAndEmployment 就業情報(職種)
      businessTypeValue={formValues.businessTypeValue}
      handleBusinessTypeSelect={handleBusinessTypeSelect}
      // BasicAndEmployment 就業情報(雇用形態)
      employmentValue={formValues.employmentValue}
      handleEmploymentSelect={handleEmploymentSelect}
      // TreatmentAndCulture 待遇面の口コミ・社風面の口コミ
      checkboxValues={formValues.checkboxValues}
      handleCheckboxChange={handleCheckboxChange}
      // WorkplaceReviewFeature 職場の口コミ(特徴)
      radioValues={formValues.radioValues}
      handleRadioChange={handleRadioChange}
      // WorkplaceReviewThought 職場の口コミ(あなたの考え・思い)
      textAreaValue={formValues.textAreaState}
      handleTextAreaChange={handleTextAreaChange}
      handleClickBackButton={handleClickBackButton}
      handleClickSubmitButton={handleClickSubmitButton}
      isShowBackupModal={isShowBackupModal}
      restoreBackupPath={restoreBackupPath}
      deleteBackupPath={deleteBackupPath}
      termsPath={termsPath}
      guidelinePath={guidelinePath}
      handleRestore={handleRestore}
      inputContainerRefs={inputContainerRefs}
      isAvailableSubmit // TODO: 変数に修正すること
    />
  );
};
