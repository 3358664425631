import { FC } from "react";
import { Table } from "@root/shared/components/Table/Table";
import { Td } from "@root/shared/components/Table/Td";
import { Th } from "@root/shared/components/Table/Th";
import { Tr } from "@root/shared/components/Table/Tr";
import { Button } from "@root/shared/components/Button";
import { RequestConfirmationPanel } from "@userFrontend/features/reward/components/RequestConfirmationPanel";
import { MultiLineText } from "@root/shared/components/MultiLineText";
import * as styles from "./styles.css";
// types
import { Props } from "./type";

export const Confirm: FC<Props> = ({
  questions,
  clientNameValue,
  businessTypeValue,
  employmentValue,
  employmentStatusValue,
  continuedServiceYearsValue,
  checkboxValues,
  radioValue,
  textAreaValue,
  termsPath,
  guidelinePath,
  handleClickBackButton,
  handleClickSubmitButton,
}) => {
  return (
    <div className={styles.container}>
      <Table>
        <Tr>
          <Th width="185px">基本情報</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>投稿先</dt>
              <dd>{clientNameValue}</dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">就業情報</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>就業状況</dt>
              <dd>
                {questions.basicAndEmploymentQuestions.employmentStatus && (
                  <>
                    {questions.basicAndEmploymentQuestions.employmentStatus
                      .filter(
                        (employmentState) => employmentState.optionId === employmentStatusValue,
                      )
                      .map((employment) => (
                        <p key={employment.optionText}>{employment.optionText}</p>
                      ))}
                  </>
                )}
              </dd>
              <dt className={styles.listTitle}>この職場での勤続年数</dt>
              <dd>
                {questions.basicAndEmploymentQuestions.continuedServiceYearsSelectOptions && (
                  <>
                    {questions.basicAndEmploymentQuestions.continuedServiceYearsSelectOptions
                      .filter(
                        (continuedServiceYearsOption) =>
                          continuedServiceYearsOption.value === continuedServiceYearsValue,
                      )
                      .map((continuedServiceYears) => (
                        <p key={continuedServiceYears.label}>{continuedServiceYears.label}</p>
                      ))}
                  </>
                )}
              </dd>
              <dt className={styles.listTitle}>職種</dt>
              <dd>
                {questions.basicAndEmploymentQuestions.businessTypesSelectOptions && (
                  <>
                    {questions.basicAndEmploymentQuestions.businessTypesSelectOptions
                      .filter(
                        (businessTypeOption) => businessTypeOption.value === businessTypeValue,
                      )
                      .map((businessType) => (
                        <p key={businessType.label}>{businessType.label}</p>
                      ))}
                  </>
                )}
              </dd>
              <dt className={styles.listTitle}>雇用形態</dt>
              <dd>
                {questions.basicAndEmploymentQuestions.employmentsSelectOptions && (
                  <>
                    {questions.basicAndEmploymentQuestions.employmentsSelectOptions
                      .filter((employmentOption) => employmentOption.value === employmentValue)
                      .map((employment) => (
                        <p key={employment.label}>{employment.label}</p>
                      ))}
                  </>
                )}
              </dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">あなたの職場の口コミ【待遇面】</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>あなたの職場の良いところを教えてください</dt>
              <dd>
                <ul className={styles.dotList}>
                  {questions.treatmentAndCultureQuestions.treatmentQuestions && (
                    <>
                      {questions.treatmentAndCultureQuestions.treatmentQuestions
                        .filter((item) => checkboxValues.treatments.includes(item.optionId))
                        .map((treatmentsQuestion) => (
                          <li key={treatmentsQuestion.optionText} className={styles.dotListItem}>
                            {treatmentsQuestion.optionText}
                          </li>
                        ))}
                    </>
                  )}
                </ul>
              </dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">あなたの職場の口コミ【社風面】</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>あなたの職場の良いところを教えてください</dt>
              <dd>
                <ul className={styles.dotList}>
                  {questions.treatmentAndCultureQuestions.cultureQuestions && (
                    <>
                      {questions.treatmentAndCultureQuestions.cultureQuestions
                        .filter((item) => checkboxValues.cultures.includes(item.optionId))
                        .map((culturesQuestion) => (
                          <li key={culturesQuestion.optionText} className={styles.dotListItem}>
                            {culturesQuestion.optionText}
                          </li>
                        ))}
                    </>
                  )}
                </ul>
              </dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">あなたの職場の口コミ【特徴】</Th>
          <Td>
            <dl className={styles.list}>
              <dt className={styles.listTitle}>あなたの職場の雰囲気は？</dt>
              <dd>
                {questions.workplaceReviewFeatureQuestions.atmosphere
                  .filter((item) => item.optionId === radioValue.atmosphere)
                  .map((atmosphereQuestion) => (
                    <p key={atmosphereQuestion.optionText}>{atmosphereQuestion.optionText}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>あなたの職場の給与体系は？</dt>
              <dd>
                {questions.workplaceReviewFeatureQuestions.salaryStandard
                  .filter((item) => item.optionId === radioValue.salaryStandard)
                  .map((salaryStandardQuestion) => (
                    <p key={salaryStandardQuestion.optionText}>
                      {salaryStandardQuestion.optionText}
                    </p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>独り立ち・デビューまでは？</dt>
              <dd>
                {questions.workplaceReviewFeatureQuestions.debut
                  .filter((item) => item.optionId === radioValue.debut)
                  .map((debutQuestion) => (
                    <p key={debutQuestion.optionText}>{debutQuestion.optionText}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>教育方針は？</dt>
              <dd>
                {questions.workplaceReviewFeatureQuestions.education
                  .filter((item) => item.optionId === radioValue.education)
                  .map((educationQuestion) => (
                    <p key={educationQuestion.optionText}>{educationQuestion.optionText}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>勤務時間は？</dt>
              <dd>
                {questions.workplaceReviewFeatureQuestions.workTime
                  .filter((item) => item.optionId === radioValue.workTime)
                  .map((workTimeQuestion) => (
                    <p key={workTimeQuestion.optionText}>{workTimeQuestion.optionText}</p>
                  ))}
              </dd>
              <dt className={styles.listTitle}>前職から比べた給与の変化</dt>
              <dd>
                {questions.workplaceReviewFeatureQuestions.salaryChange
                  .filter((item) => item.optionId === radioValue.salaryChange)
                  .map((salaryChangeQuestion) => (
                    <p key={salaryChangeQuestion.optionText}>{salaryChangeQuestion.optionText}</p>
                  ))}
              </dd>
            </dl>
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">
            {questions.workplaceReviewThoughtQuestions.workplaceMatching.questionText}
          </Th>
          <Td>
            <MultiLineText text={textAreaValue.workplaceMatching} />
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">
            {questions.workplaceReviewThoughtQuestions.interestReward.questionText}
          </Th>
          <Td>
            <MultiLineText text={textAreaValue.interestReward} />
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">{questions.workplaceReviewThoughtQuestions.challenge.questionText}</Th>
          <Td>
            <MultiLineText text={textAreaValue.challenge} />
          </Td>
        </Tr>
        <Tr>
          <Th width="185px">
            {questions.workplaceReviewThoughtQuestions.expectation.questionText}
          </Th>
          <Td>
            <MultiLineText text={textAreaValue.expectation} />
          </Td>
        </Tr>
      </Table>
      <div className={styles.panelOuter}>
        <RequestConfirmationPanel termsUrl={termsPath} guidelineUrl={guidelinePath} />
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonOuter}>
          <Button theme="tertiary" onClick={handleClickBackButton}>
            戻る
          </Button>
          <Button theme="primary" onClick={handleClickSubmitButton}>
            投稿する
          </Button>
        </div>
      </div>
    </div>
  );
};
