import { FC, useState } from "react";
import { axiosPost } from "@root/utils/axiosForRails";
import { AxiosError } from "axios";
import { Presentation } from "./Presentation";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

type Props = {
  initialIsShow: boolean;
  restoredNewUserRewardUrl: string;
  deleteBackupUrl: string;
  mypageUrl: string;
};

export const RestoreUserRewardInputsModal: FC<Props> = ({
  initialIsShow,
  restoredNewUserRewardUrl,
  deleteBackupUrl,
  mypageUrl,
}) => {
  const [isShow, setIsShow] = useState(initialIsShow);

  const deleteBackup = async () => {
    await axiosPost.post(deleteBackupUrl).catch((error: AxiosError) => {
      if (typeof newrelic !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        newrelic.noticeError(error.message);
      }
    });
    setIsShow(false);
  };

  const linkToMypage = () => {
    window.location.href = mypageUrl;
  };

  return (
    <Presentation
      isShow={isShow}
      closeModalHandler={() => setIsShow(false)}
      deleteBackupHandler={deleteBackup}
      linkToMypageHandler={linkToMypage}
      restoreUrl={restoredNewUserRewardUrl}
    />
  );
};
