import { FC } from "react";
import { CautionaryNoteBox } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/CautionaryNoteBox";
import { SectionWithTitleAndLabel } from "@userFrontend/components/SectionWithTitleAndLabel";
import { SectionTitle } from "@userFrontend/features/clientReview/components/ClientReviewInputs/uiParts/SectionTitle/";
import { ClientSelect } from "./uiParts/ClientSelect";
import * as styles from "./styles.css";
import { EmploymentStatusRadio } from "./uiParts/EmploymentStatusRadio";
import { SelectBoxContents } from "./uiParts/SelectBoxContents";
import { Props } from "./type";

export const BasicAndEmployment: FC<Props> = ({
  questions,
  client,
  clientRef,
  handleShowModal,
  clientErrorText,
  employmentStatusValue,
  handleEmploymentStatusChange,
  employmentStatusErrorText,
  employmentStatusRef,
  continuedServiceYearsValue,
  handleContinuedServiceYearSelect,
  continuedServiceYearsErrorText,
  continuedServiceYearsRef,
  businessTypeValue,
  handleBusinessTypeSelect,
  businessTypesErrorText,
  businessTypeIdRef,
  employmentValue,
  handleEmploymentSelect,
  employmentsErrorText,
  employmentIdRef,
  isShowSearchModal,
  handleCloseModal,
  clientNameValue,
  shopNameValue,
  clientNameErrorText,
  shopNameErrorText,
  handleClientSearch,
  handleChangeClientNameValue,
  handleChangeShopNameValue,
  searchResults,
  handleClientSelect,
  isShowClientsDetail,
  handleShowTextButton,
  searchResultErrorText,
  displaySearchText,
  isSearching,
  handleReturnToMyPageTop,
  userContactPath,
}) => (
  <>
    <SectionTitle text="基本情報" />
    <div className={styles.basicInner}>
      <div className={styles.cautionaryNoteBoxOuter}>
        <CautionaryNoteBox />
      </div>
      <div className={styles.inputOuter}>
        <ClientSelect
          client={client}
          clientRef={clientRef}
          clientErrorText={clientErrorText}
          handleShowModal={handleShowModal}
          isShowSearchModal={isShowSearchModal}
          handleCloseModal={handleCloseModal}
          clientNameValue={clientNameValue}
          shopNameValue={shopNameValue}
          clientNameErrorText={clientNameErrorText}
          shopNameErrorText={shopNameErrorText}
          handleClientSearch={handleClientSearch}
          handleChangeClientNameValue={handleChangeClientNameValue}
          handleChangeShopNameValue={handleChangeShopNameValue}
          searchResults={searchResults}
          handleClientSelect={handleClientSelect}
          isShowClientsDetail={isShowClientsDetail}
          handleShowTextButton={handleShowTextButton}
          searchResultErrorText={searchResultErrorText}
          displaySearchText={displaySearchText}
          isSearching={isSearching}
          handleReturnToMyPageTop={handleReturnToMyPageTop}
          userContactPath={userContactPath}
        />
      </div>
    </div>
    <SectionTitle text="就業情報" />
    <div>
      <div className={styles.employmentInner}>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            title="就業状況"
            labelType="required"
            containerRef={employmentStatusRef}
          >
            {questions.employmentStatus && (
              <EmploymentStatusRadio
                employmentStatus={questions.employmentStatus}
                handleEmploymentStatusChange={handleEmploymentStatusChange}
                employmentStatusValue={employmentStatusValue}
                employmentStatusErrorText={employmentStatusErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            title="この職場での勤続年数"
            labelType="required"
            containerRef={continuedServiceYearsRef}
          >
            {questions.continuedServiceYearsSelectOptions && (
              <SelectBoxContents
                categoryName="continuedServiceYear"
                value={continuedServiceYearsValue}
                handleSelect={handleContinuedServiceYearSelect}
                selectOptions={questions.continuedServiceYearsSelectOptions}
                errorText={continuedServiceYearsErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            title="職種"
            labelType="required"
            containerRef={businessTypeIdRef}
          >
            {questions.businessTypesSelectOptions && (
              <SelectBoxContents
                categoryName="businessType"
                value={businessTypeValue}
                handleSelect={handleBusinessTypeSelect}
                selectOptions={questions.businessTypesSelectOptions}
                errorText={businessTypesErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
        <div className={styles.inputOuter}>
          <SectionWithTitleAndLabel
            title="雇用形態"
            labelType="required"
            containerRef={employmentIdRef}
          >
            {questions.employmentsSelectOptions && (
              <SelectBoxContents
                categoryName="employment"
                value={employmentValue}
                handleSelect={handleEmploymentSelect}
                selectOptions={questions.employmentsSelectOptions}
                errorText={employmentsErrorText}
              />
            )}
          </SectionWithTitleAndLabel>
        </div>
      </div>
    </div>
  </>
);
