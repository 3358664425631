/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState, useRef, useEffect, useMemo } from "react";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { TextArea, ValidationErrorMessage } from "@legacy_user_frontend/components/ui_parts";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import { SaveAndCancelButtonArea } from "@userFrontend/features/userInfo/components/SaveAndCancelButtonArea";
import { PrivateLabel } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/private_label";
import { toSnakeCase } from "@root/utils";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { CancelModal } from "@userFrontend/features/userInfo/components/CancelModal";
import {
  TempCredentialsAttributesValuesProps,
  CredentialsEditProps,
  InputProps,
} from "./src/credentials_edit_props";
import { Experiences } from "./src/ui_parts/experiences";
import { CredentialItemList } from "./src/ui_parts/credential_item_list";
import { HiddenCredentialForm } from "./src/ui_parts/hidden_credential_form";
import { SelectedCredentialLabels } from "./src/ui_parts/selected_credential_labels";
import {
  StyledCredentialHeading,
  StyledCredentialDescription,
  StyledRemarksOuter,
  StyledCredentialItemListOuter,
  StyledErrorMessageOuter,
} from "./src/styled_elements";

export const CredentialsEdit: FC<CredentialsEditProps> = ({
  action,
  entryExperiences,
  experiencesNote,
  experienceOptionsFetchPath,
  credentialItems,
  categorizedCredentialItemList,
  otherCredentialItemList,
  errors,
  returnPath,
  categorizedJobTypeList,
  isBiyo,
  appOsType,
}) => {
  // 資格IDの最大値 +1を取得
  const credentialItemsMaxLength = useMemo(
    () =>
      Math.max(
        ...categorizedCredentialItemList.map((categoryItem) =>
          Math.max(
            ...categoryItem.jobTypes.map(
              (jobTypeItem) =>
                Math.max(...jobTypeItem.credentialItems.map(({ value }) => Number(value))) + 1,
            ),
          ),
        ),
      ),
    [categorizedCredentialItemList],
  );
  // 資格の初期値
  const defaultCredentialsAttributesValues: TempCredentialsAttributesValuesProps = useMemo(
    () =>
      [...Array(credentialItemsMaxLength)].map(() => ({
        user_credential_item_id: "",
        will_flag: false,
      })),
    [credentialItemsMaxLength],
  );
  credentialItems.forEach(({ userCredentialItemId, isWill }) => {
    defaultCredentialsAttributesValues[Number(userCredentialItemId)] = {
      user_credential_item_id: userCredentialItemId,
      will_flag: isWill,
    };
  });
  // 初期値の設定
  const entryExperiencesValues = useMemo(
    () =>
      entryExperiences.map((value) => ({
        job_type_id: value.jobTypeId,
        job_type_experience_id: value.jobTypeExperienceId,
      })),
    [entryExperiences],
  );
  const defaultValues = useMemo(
    () => ({
      experiences_note: experiencesNote,
      experiences_attributes: entryExperiencesValues,
      credentials_attributes: defaultCredentialsAttributesValues,
    }),
    [defaultCredentialsAttributesValues, entryExperiencesValues, experiencesNote],
  );
  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      form_user_frontend_user_update: defaultValues,
    },
  });

  // サーバー側から渡ってきたエラーメッセージを項目に反映する
  const { setError } = methods;
  useEffect(() => {
    const setErrorMessage = (key: string, message: string, index?: number) => {
      const camelCaseKey = toSnakeCase(key);
      const errorFormName = `form_user_frontend_user_update.${camelCaseKey}` as keyof InputProps;
      setError(
        errorFormName,
        {
          type: "manual",
          message,
        },
        { shouldFocus: index === 0 },
      );
    };
    Object.keys(errors).forEach((key, index) => {
      setErrorMessage(key, errors[key], index);
    });
    entryExperiences.forEach((entryExperience, index) => {
      const experienceErrors = entryExperience.errors;
      Object.keys(experienceErrors).forEach((key) => {
        setErrorMessage(`experiences_attributes.${index}.${key}`, experienceErrors[key], index);
      });
    });
  }, [entryExperiences, errors, setError]);
  const credentialsError = methods.formState.errors?.form_user_frontend_user_update?.credentials;

  // 変更の検出
  const watchedValues = useWatch({
    control: methods.control,
    name: "form_user_frontend_user_update",
  });
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (JSON.stringify(watchedValues) !== JSON.stringify(defaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [defaultValues, watchedValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit = () => {
    setIsShowAlert(false);
    formEl.current?.submit();
  };

  // Experiencesコンポーネントに渡す経験年数リストの作成
  const experienceLists = entryExperiences.map(({ experienceList }) => experienceList);

  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          <BoxWithShadow>
            <StyledCredentialHeading>経験職種</StyledCredentialHeading>
            <StyledCredentialDescription>
              ［経験職種］を入力する場合、［経験年数］が必須です。
            </StyledCredentialDescription>
            <Experiences
              experienceOptionsFetchPath={experienceOptionsFetchPath}
              categorizedJobTypeList={categorizedJobTypeList}
              experienceLists={experienceLists}
            />
            <StyledRemarksOuter>
              <DefinitionList>
                <dt>
                  備考
                  <span className="private-label-outer">
                    <PrivateLabel />
                  </span>
                </dt>
                <dd>
                  <TextArea
                    name="form_user_frontend_user_update[experiences_note]"
                    placeholder=""
                  />
                </dd>
              </DefinitionList>
            </StyledRemarksOuter>
          </BoxWithShadow>
          <BoxWithShadow>
            <StyledCredentialHeading>資格</StyledCredentialHeading>
            <StyledCredentialDescription>
              国家資格を登録する場合は、[経験職種]の登録が必要です。
            </StyledCredentialDescription>
            <>
              {credentialsError && credentialsError.message && (
                <StyledErrorMessageOuter>
                  <ValidationErrorMessage message={credentialsError.message} />
                </StyledErrorMessageOuter>
              )}
            </>
            <SelectedCredentialLabels
              categorizedCredentialItemList={categorizedCredentialItemList}
              otherCredentialItemList={otherCredentialItemList}
            />
            <StyledCredentialItemListOuter>
              <CredentialItemList
                categorizedCredentialItemList={categorizedCredentialItemList}
                otherCredentialItemList={otherCredentialItemList}
                isBiyo={isBiyo}
              />
            </StyledCredentialItemListOuter>
            <HiddenCredentialForm
              credentialItemsMaxLength={credentialItemsMaxLength}
              categorizedCredentialItemList={categorizedCredentialItemList}
              otherCredentialItemList={otherCredentialItemList}
            />
          </BoxWithShadow>
          <SaveAndCancelButtonArea onCancel={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <CancelModal isShow={isModalOpen} onClose={closeModal} returnPath={returnPath} />
    </>
  );
};
