export type ResponseWithStatus = {
  status?: number | boolean;
  message?: string;
};

type Props = {
  url: string;
  params?: Record<string, string | string[]>;
  method?: "GET" | "POST";
  handleError: (e?: Error) => void;
};

export const FetchJson = <T extends ResponseWithStatus>({
  url,
  params,
  method,
  handleError,
}: Props): Promise<T | null> => {
  const queryParams = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => queryParams.append(`${key}[]`, String(v)));
      } else {
        queryParams.append(key, String(value));
      }
    });
  }
  const urlWithParams = params ? `${url}?${queryParams}` : url;
  const postOptions = {
    method,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `${queryParams}`,
  };

  return fetch(method === "POST" ? url : urlWithParams, method === "POST" ? postOptions : undefined)
    .then((res) => {
      if (!res.ok) {
        handleError();
        return null;
      }
      return res.json();
    })
    .then((json: T) => {
      // アプリケーション側のエラー補足(エラー時は{status: boolean, data: [], message: string}が返ってくる)
      if (json.status !== undefined && !json.status) {
        handleError();
        return null;
      }
      return json;
    })
    .catch((error: Error) => {
      handleError(error);
      return null;
    });
};
