import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledCassetteContent = styled.div`
  margin-top: 12px;
`;

export const StyledCassetteDescription = styled.p`
  color: ${Colors.LIGHT_BLACK};
  line-height: 1.5;
`;

export const StyledTableList = styled.div`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  dt,
  dd {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 8px 0;

    &:not(:first-of-type) {
      padding-top: 20px;
      border-top: 1px solid ${Colors.MEDIUM_GREY};
    }

    &:not(:last-of-type) {
      padding-bottom: 20px;
    }
  }

  dt {
    position: relative;
    width: 100px;
    font-size: 12px;

    > div {
      width: 100%;
    }
  }

  dd {
    width: calc(100% - 100px);
    padding-left: 16px;
    font-size: 14px;
    font-weight: bold;
    word-break: break-all;
  }

  .address-annotation {
    margin-top: 8px;
    font-size: 10px;
  }

  .address-annotation-line-break {
    ${Breakpoints.MEDIUM} {
      display: none;
    }
  }
`;

export const StyledTagLabel = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  border: 1px solid ${Colors.REJOB_BRAND_DEEP};
  background-color: ${Colors.WHITE};
  color: ${Colors.REJOB_BRAND_DEEP};
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
`;

export const StyledList = styled.ul`
  list-style-type: none;
`;

export const StyledTagLabelList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const StyledInnerCassetteList = styled.ul`
  list-style-type: none;

  li + li {
    margin-top: 12px;
  }
`;

export const StyledInnerCassette = styled.section`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: ${Colors.WHITE};
`;

export const StyledInnerCassetteHeadingOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px 4px 0px 0px;
  background-color: ${Colors.GREY};
`;

export const StyledInnerCassetteHeading = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${Colors.LIGHT_BLACK};
  font-size: 14px;
  font-weight: bold;
`;

export const StyledInnerCassetteContent = styled.div`
  padding: 16px;
  border-radius: 0px 0px 4px 4px;
`;

export const StyledGhostButtonLinkOuter = styled.div`
  margin-top: 16px;
`;

export const StyledGhostButtonLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  border: 1px solid ${Colors.MEDIUM_GREY};
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.LIGHT_BLACK};
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  &::before {
    content: "";
    display: block;
    width: 8px;
    height: 9px;
    margin-right: 8px;
    background-image: url("/images/user_frontend/global/icon/icn_plus_map_button.svg");
    background-size: contain;
  }
`;

export const StyledSelfPrList = styled.dl`
  > dt {
    font-weight: bold;
  }

  > dd + dt {
    margin-top: 8px;
    padding-top: 16px;
    border-top: 1px solid ${Colors.MEDIUM_GREY};
  }

  > dd {
    margin-top: 10px;
    padding-bottom: 8px;
    color: ${Colors.OLD_DEEP_BLACK};
    word-break: break-all;
  }
`;

export const StyledSliderOuter = styled.div`
  margin-top: 16px;
  margin-right: -16px;

  ${Breakpoints.MEDIUM} {
    margin-top: 8px;
    margin-right: 0;
    padding: 16px;
  }

  .slider {
    ${Breakpoints.MEDIUM} {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
`;

export const StyledSliderImageOuter = styled.div`
  position: relative;
  width: 94px;
  margin-right: 16px;
  padding-top: 125px;
  background-color: ${Colors.LIGHT_GRAY_02};

  ${Breakpoints.MEDIUM} {
    margin-right: 0;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const StyledSliderDescription = styled.p`
  width: 94px;
  margin-top: 8px;
  margin-right: 16px;
  word-wrap: break-word;

  ${Breakpoints.MEDIUM} {
    margin-right: 0;
  }
`;

export const StyledSkillList = styled.dl`
  margin-top: 12px;

  > dt {
    color: ${Colors.DEEP_GREY};
  }

  > dd {
    margin-top: 8px;

    & + dt {
      margin-top: 12px;
    }
  }
`;

export const StyledCategorizedSkillList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
