import "app/packs/shared/components/Modal/legacy/styles.css.ts.vanilla.css!=!../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/packs/shared/components/Modal/legacy/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81VXbOaMBB991dkptMZmLmxiIqoL33yfwQSIL0xYUIUtON/bz5Aold7a/vS0WFgs9k952Q5TI/npFqVS/BzAkAtGqqo4BtQ0I7grQ4pUW9AZO4YKVR/21Ksqg2YRdGxNc8VoWWlXKAygQzl76UUB45hLpiQGyDLDAXRG+j/0yQ0eWdIOSad3TnbTi6TqYOTPIOTCaXE/gOMrz6KHLE8MEEAwTyqu/Axoi+73c6VlJhIKBGmh0ZXS+rOXSLXJRMdbCqERavbmvCwfk9pEXoMVncMUNYIdlDkqunSgXaq9g9KIt4UQmqC9pYhRQKoF9+AuYYeaZ/kIu1J7lEH+/UjkgGEDsviujhItExXdfeyLH+vRmrVEEciCyZaeNqAJpeCMS9lfZOiBTsoYRrWCGPKS93N9VkMHe85+Yxjvzn6rPJL1TJbDdOmZkjzKBixe38cGkWLk1aRK8LvCszsASBGSw6pInutZ66TiPTH1t8QhbcAY805Nbxjj/yjs1ub3x9PNT3b8n2uDnk88/+H52dUns7w+Ppp0Pn76dbQRvPxaGNLu9DsjDxkHA5GOblOR7xwQZvX9sEkitwUyZLywaIqrQNsapTrUly0EtXbm2GsKMaEW2CkU3BcIIzRuqGNh41YbP0LPo8dhAHS8OzUMs04eayOtZYaSX0yI17ojMjyujYs/sWG/XOev+bB0fCyO/Evk+97gikypkEIB4hjEOw15r7zOjUub7HefD+e+e/gutZDA/sV0y6azuemyjcQhy7JTsrMkRopXwN9c7vNRR467FMnvYxwi9/CvUwuvwCAluKwpgcAAA==\"}!../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = 'vz6h7g5';
export var closeButton = 'vz6h7ge';
export var content = 'vz6h7g9';
export var contentBottomSheet = 'vz6h7ga';
export var contentHeight = 'var(--vz6h7g2)';
export var footer = 'vz6h7gf';
export var footerHeight = 'var(--vz6h7g3)';
export var header = 'vz6h7gc';
export var headerBottomSheet = 'vz6h7gb';
export var headerHeight = 'var(--vz6h7g0)';
export var headerTitlePosition = 'var(--vz6h7g1)';
export var maxWidth = 'var(--vz6h7g4)';
export var title = 'vz6h7gd';
export var wrapper = 'vz6h7g7';
export var wrapperBottomSheet = 'vz6h7g6';
export var wrapperScroll = 'vz6h7g8';