import { FC } from "react";
import { useCookies } from "react-cookie";
import { Presentation } from "./Presentation";

// cookieの有効期限の上限は400日
const MAXIMUM_EXPIRY_DATE = 400;

export const AttentionOnUnsolicitedEmail: FC = () => {
  const cookieName = "never_show_unsolicited_email_attention";

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);

  // 期限日を日時に変換
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + MAXIMUM_EXPIRY_DATE * 24 * 60 * 60 * 1000);

  const handleClickLink = () => {
    setCookie(cookieName, true, { path: "/", expires: expirationDate });
  };

  return <Presentation handleClickLink={handleClickLink} />;
};
