import { Button } from "@root/shared/components/Button";
import { FC, Ref } from "react";
import * as styles from "./styles.css";

type Props = {
  elementRef: Ref<HTMLDivElement>;
  isFixed: boolean;
  onCancel: () => void;
};

export const Presentation: FC<Props> = ({ elementRef, isFixed, onCancel }) => {
  return (
    <div className={styles.wrapper} ref={elementRef}>
      <div className={styles.buttonWrapper[isFixed ? "isFixed" : "default"]}>
        <Button type="button" theme="secondary" onClick={onCancel} className={styles.button}>
          キャンセル
        </Button>
        <Button type="submit" theme="primary" className={styles.button}>
          保存する
        </Button>
      </div>
    </div>
  );
};
