import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = {
  currentStep: 1 | 2 | 3;
};

type StepProps = {
  label: string;
  stepNum: number;
  currentStep: number;
  isLastStep: boolean;
};

const Step: FC<StepProps> = ({ label, stepNum, currentStep, isLastStep }) => {
  const isCurrentStep = stepNum === currentStep;
  const isPrevStep = stepNum < currentStep;

  return (
    <span
      className={styles.chartItemWrapper({
        isCurrentStep,
        isPrevStep,
      })}
    >
      <span className={styles.chartItem}>
        {isLastStep || isPrevStep ? (
          <div className={styles.iconWrapper({ isLastStep })}>
            <Icon name="done" fontSize="16px" />
          </div>
        ) : (
          stepNum
        )}
      </span>
      <div className={styles.chartItemText({ isCurrentStep })}>{label}</div>
    </span>
  );
};

const stepTextArray = ["基本情報", "企業への口コミ", "申請完了"];

export const UserRewardStepIndicator: FC<Props> = ({ currentStep }) => {
  return (
    <div className={styles.chartWrapper}>
      {stepTextArray.map((label, index) => {
        const stepNum = index + 1;
        const isLastStep = stepNum === stepTextArray.length;

        return (
          <Step
            key={label}
            label={label}
            stepNum={stepNum}
            currentStep={currentStep}
            isLastStep={isLastStep}
          />
        );
      })}
    </div>
  );
};
