import { FC } from "react";
import { GuidanceBox } from "@root/shared/components/GuidanceBox";
import { TextLink } from "@root/shared/components/TextLink";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = {
  handleClickLink: () => void;
};

const LINK_URL = "https://relax-job.com/contents_list/client_contents/rejob_information";

export const Presentation: FC<Props> = ({ handleClickLink }) => (
  <GuidanceBox backgroundColor="gray">
    <p className={styles.iconAndText}>
      <span className={styles.iconOuter}>
        <Icon name="information" />
      </span>
      リジョブを名乗る不審メールにご注意ください
    </p>
    <p className={styles.linkOuter}>
      <TextLink href={LINK_URL} target="_blank" rel="noopener noreferrer" onClick={handleClickLink}>
        求人サイトリジョブを名乗る不審なメールに関する注意喚起
      </TextLink>
    </p>
  </GuidanceBox>
);
