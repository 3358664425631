import { useEffect, useState } from "react";
import axios from "axios";
import { showAlert } from "@root/utils/showAlert";
import { SearchResult } from "./type";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

type ReturnType = {
  isShowClientsDetail: {
    clientId: number;
    isShowShopName: boolean;
  }[];
  clientNameValue: string;
  shopNameValue: string;
  searchResults: SearchResult[];
  searchResultErrorText: string | undefined;
  isSearching: boolean;
  handleClientSearch: () => Promise<void>;
  handleShowTextButton: (clientId: number) => void;
  handleChangeClientNameValue: (value: string) => void;
  handleChangeShopNameValue: (value: string) => void;
  handleReturnToMyPageTop: () => void;
};

type Response = {
  data: SearchResult[];
  message: string;
};

type ErrorResponse = {
  status: number;
  statusText?: string;
  data: {
    message: string;
  };
};

export const useSearchModal = (mypageTopPath: string, clientSearchPath: string): ReturnType => {
  const [isShowClientsDetail, setIsShowClientsDetail] = useState<
    { clientId: number; isShowShopName: boolean }[]
  >([]);
  const [clientNameValue, setClientNameValue] = useState("");
  const [shopNameValue, setShopNameValue] = useState("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searchResultErrorText, setSearchResultErrorText] = useState<string | undefined>();
  const [isSearching, setIsSearching] = useState(false);

  // 投稿先企業を検索するハンドラ
  const handleClientSearch = async () => {
    setIsSearching(true);
    const body = {
      form_user_frontend_client_search: {
        client_name: clientNameValue,
        client_shop_name: shopNameValue,
      },
    };
    setSearchResultErrorText(undefined);
    await axios
      .get<Response>(clientSearchPath, { params: body })
      .then(({ data }) => {
        setIsSearching(false);
        setSearchResults(data.data);
        if (data.message) {
          setSearchResultErrorText(data.message);
          return;
        }
        if (data.data.length === 0) {
          setSearchResultErrorText("ヒットする企業はありませんでした。");
        }
      })
      .catch((error) => {
        const response: ErrorResponse = error.response as ErrorResponse;
        setIsSearching(false);
        setSearchResults([]);
        switch (response.status) {
          case 400:
            setSearchResultErrorText(response.data.message);
            break;
          default:
            if (typeof newrelic !== "undefined") {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              newrelic.noticeError(response.statusText);
            }
            showAlert("送信に失敗しました。時間をおいて再度お試しください。");
        }
      });
  };

  // 投稿先企業検索一覧の詳細表示
  const handleShowTextButton = (clientId: number) => {
    setIsShowClientsDetail((prevStates) =>
      prevStates.map((prevState) => ({
        clientId: prevState.clientId,
        isShowShopName: prevState.clientId === clientId ? true : prevState.isShowShopName,
      })),
    );
  };

  // 投稿先企業検索の入力情報
  const handleChangeClientNameValue = (value: string) => setClientNameValue(value);
  const handleChangeShopNameValue = (value: string) => setShopNameValue(value);

  // 口コミ投稿を中断して戻るハンドラ
  const handleReturnToMyPageTop = () => {
    window.location.href = mypageTopPath;
  };

  // 投稿先企業検索一覧の詳細表示 の状態更新
  useEffect(() => {
    setIsShowClientsDetail(
      searchResults.map((searchResult) => ({
        clientId: searchResult.clientId,
        isShowShopName: false,
      })),
    );
  }, [searchResults]);

  return {
    isShowClientsDetail,
    clientNameValue,
    shopNameValue,
    searchResults,
    searchResultErrorText,
    isSearching,
    handleClientSearch,
    handleShowTextButton,
    handleChangeClientNameValue,
    handleChangeShopNameValue,
    handleReturnToMyPageTop,
  };
};
