/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { AdditionButton } from "@userFrontend/components/Buttons/AdditionButton";
import { SaveAndCancelButtonArea } from "@userFrontend/features/userInfo/components/SaveAndCancelButtonArea";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { CancelModal } from "@userFrontend/features/userInfo/components/CancelModal";
import { schema, InputProps } from "./src/schema";
import { AchievementEditProps } from "./src/achievement_edit_props";
import { StyledAdditionButtonOuter } from "./src/styled_elements";
import { Cassette } from "./ui_parts/cassette";

export const AchievementEdit: FC<AchievementEditProps> = ({
  action,
  returnPath,
  yearList,
  monthList,
  achievements,
  appOsType,
}) => {
  const formName = "form_user_achievement_collection[user_achievements_attributes]";
  const dottedFormName = "form_user_achievement_collection.user_achievements_attributes";

  // 初期値の設定
  const defaultValues = achievements.map((achievement) => achievement);
  if (!achievements.length) {
    defaultValues.push({ name: "", year: "", month: "", errors: {} });
  }

  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      form_user_achievement_collection: {
        user_achievements_attributes: defaultValues,
      },
    },
    resolver: valibotResolver(schema),
  });

  // 受賞歴fieldArrayの作成
  const { control, setError } = methods;
  const { fields, remove, append } = useFieldArray({
    control,
    name: dottedFormName,
  });

  // フォームを最低1つ以上表示する
  useEffect(() => {
    if (fields.length === 0) {
      append({
        name: "",
        year: "",
        month: "",
      });
    }
  }, [append, fields]);

  // サーバから渡ってきたエラーを項目に反映する
  useEffect(() => {
    const defaultErrors = achievements.map(({ errors }) => errors);
    const setErrorMessage = (key: string, index: number) => {
      const errorFormName = `${dottedFormName}.${index}.${key}` as keyof InputProps;
      setError(
        errorFormName,
        {
          type: "manual",
          message: defaultErrors[index][key],
        },
        { shouldFocus: index === 0 },
      );
    };
    defaultErrors.forEach((achivementErrors, index) => {
      Object.keys(achivementErrors).forEach((key) => {
        setErrorMessage(key, index);
      });
    });
  }, [achievements, setError]);

  // URLにアンカーリンクがあった場合の対応
  useEffect(() => {
    const anchor = window.location.hash;
    if (!anchor) {
      return;
    }
    // 一覧画面で追加ボタンを押して遷移した場合はカセットを1つ追加し、新しいカセットにスクロールする
    if (anchor === `#cassette-${fields.length}`) {
      append({ name: "", year: "", month: "" }, { shouldFocus: true });
      return;
    }
    // それ以外の場合は対応するカセットにスクロールする
    window.location.href = anchor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 変更の検出
  const watchedValues = methods.watch(dottedFormName);
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (JSON.stringify(watchedValues) !== JSON.stringify(defaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [defaultValues, watchedValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit: SubmitHandler<InputProps> = () => {
    setIsShowAlert(false);
    formEl.current?.submit();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          {fields.map((field, index) => (
            <Cassette
              key={field.id}
              cassetteIndex={index}
              removeCassette={() => remove(index)}
              formName={formName}
              yearList={yearList}
              monthList={monthList}
            />
          ))}
          <StyledAdditionButtonOuter id="cassette-add">
            <AdditionButton onClick={() => append({ name: "" })}>追加する</AdditionButton>
          </StyledAdditionButtonOuter>
          <SaveAndCancelButtonArea onCancel={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <CancelModal isShow={isModalOpen} onClose={closeModal} returnPath={returnPath} />
    </>
  );
};
