import { FC, useState } from "react";
import { Accordion } from "@root/shared/components/Accordion";
import { Checkbox } from "@root/shared/components/Checkbox";
import * as styles from "./styles.css";

type SelectOption = {
  label: string;
  value: number;
};

type Reasons = {
  options: SelectOption[];
  defaultSelectedOptions: number[];
  optionsError: string | null;
  defaultOtherReasonText: string | null;
  otherReasonTextError: string | null;
};

type CommentForRejob = {
  defaultValue: string | null;
  error: string | null;
};

type Props = {
  reasons: Reasons;
  commentForRejob: CommentForRejob;
};

// Checkboxのlabel"その他"に対応するvalue
const OTHER_ID_OF_SELECT_OPTIONS = 8;

export const AboutRejobServiceInputs: FC<Props> = ({ reasons, commentForRejob }) => {
  const [selectedOptions, setSelectedOptions] = useState(reasons.defaultSelectedOptions);
  const [isAccordionOpen, setIsAccordionOpen] = useState({
    reason: reasons.defaultSelectedOptions.length > 0,
    commentForRejob: Boolean(commentForRejob.defaultValue),
  });

  const toggleAccordion = (key: "reason" | "commentForRejob") => {
    setIsAccordionOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleOptionChange = (checked: boolean, option: number) => {
    if (checked) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
    }
  };

  return (
    <>
      <div className={styles.accordionBox}>
        <Accordion
          title="リジョブを利用した理由"
          isOpened={isAccordionOpen.reason}
          onClick={() => toggleAccordion("reason")}
        >
          <>
            <ul className={styles.reasonList}>
              {reasons.options.map(({ label, value }) => (
                <li key={value} className={styles.checkboxListItem}>
                  <label htmlFor={`reasons_${value}`} className={styles.checkboxLabel}>
                    <Checkbox
                      name="form_user_frontend_user_reward_step1[reasons][]"
                      value={value}
                      defaultChecked={reasons.defaultSelectedOptions.includes(value)}
                      id={`reasons_${value}`}
                      onChange={(e) => handleOptionChange(e.target.checked, value)}
                    />
                    {label}
                  </label>
                </li>
              ))}
            </ul>
            {reasons.optionsError && <p className={styles.errorMsg}>{reasons.optionsError}</p>}
            <>
              <textarea
                name="form_user_frontend_user_reward_step1[other_comment]"
                rows={3}
                maxLength={50}
                className={styles.otherComment}
                placeholder="その他を選んだ方はご記入ください。"
                defaultValue={reasons.defaultOtherReasonText || undefined}
                disabled={!selectedOptions.includes(OTHER_ID_OF_SELECT_OPTIONS)}
              />
              {reasons.otherReasonTextError && (
                <p className={styles.errorMsg}>{reasons.otherReasonTextError}</p>
              )}
              <p className={styles.inputMaxLength}>（最大全角50文字）</p>
            </>
          </>
        </Accordion>
      </div>
      <div className={styles.accordionBox}>
        <Accordion
          title="リジョブの感想"
          isOpened={isAccordionOpen.commentForRejob}
          onClick={() => toggleAccordion("commentForRejob")}
        >
          <>
            <textarea
              name="form_user_frontend_user_reward_step1[comment]"
              rows={8}
              maxLength={300}
              className={styles.comment}
              placeholder="ご感想をご記入ください。"
              defaultValue={commentForRejob.defaultValue || undefined}
            />
            {commentForRejob.error && <p className={styles.errorMsg}>{commentForRejob.error}</p>}
            <p className={styles.inputMaxLength}>（最大全角300文字）</p>
          </>
        </Accordion>
      </div>
    </>
  );
};
