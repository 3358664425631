import { FC } from "react";
import { InView } from "react-intersection-observer";
import { sendGaEvent } from "@root/utils/sendGaEvent";
import * as styles from "./styles.css";

export type Props = {
  type: "billboard" | "registration" | "oiwai" | "appPromotion";
  path?: string;
  gaParam?: {
    action: string;
    label?: string;
  };
  pcImage: string;
  spImage: string;
  alt: string;
  isSmall?: boolean;
  lazyLoad?: boolean;
};

const LinkWrapper = ({
  isWrap,
  wrapper,
  children,
}: {
  isWrap: boolean;
  wrapper: (child: JSX.Element) => JSX.Element;
  children: JSX.Element;
}) => (isWrap ? wrapper(children) : children);

export const Presentation: FC<Props> = ({
  type,
  path,
  gaParam,
  pcImage,
  spImage,
  alt,
  isSmall,
  lazyLoad,
}) => {
  const isPc = () => window.matchMedia("(min-width: 768px)").matches;

  return (
    <InView
      className={styles.styledInView[type]}
      triggerOnce={!false}
      onChange={(inView, entry) =>
        inView &&
        entry.isIntersecting &&
        gaParam != null &&
        sendGaEvent({ category: "Impression", action: gaParam.action, label: gaParam.label })
      }
    >
      <LinkWrapper
        isWrap={path != null}
        wrapper={(children) => (
          <a
            href={path}
            onClick={() =>
              gaParam != null &&
              sendGaEvent({ category: "Click", action: gaParam.action, label: gaParam.label })
            }
          >
            {children}
          </a>
        )}
      >
        <div className={styles.imageContainer}>
          <div className={styles.spacer}>
            <img
              className={styles.keyVisual[isSmall ? "small" : "default"]}
              src={isPc() ? pcImage : spImage}
              alt={alt}
              loading={lazyLoad ? "lazy" : "eager"}
            />
          </div>
        </div>
      </LinkWrapper>
    </InView>
  );
};
